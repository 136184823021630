import React, { useState, useEffect, useLayoutEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { graphql, navigate } from 'gatsby'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import Seo from '~components/Seo'
import Section from '~components/Section'
import DataViewer from '~utils/DataViewer'
import { useCart } from '~context/siteContext'
import { useLocation } from '@reach/router'
import PageHeader from '~components/PageHeader'
import RenderModules from '~components/RenderModules'
import { mobile } from '~styles/global'
import Button from '~components/Button'
import SlideDown from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'
import resolveLink from '~utils/resolveLink'

const Product = ({ data }) => {
	const { page } = data
	const { addItem } = useCart()
	const [viewCart, setViewCart] = useState(false)
	const selectedVariant = page?.store?.variants[0]

	const add = () => {
		addItem({
			...selectedVariant.store,
			title: page?.store?.title,
			qty: 1,
			link: resolveLink(page)
		})
		setViewCart(true)
	}

	const available = true

	return(
		<>
			<Seo 
				title={page?.title}
				metaTitle={page?.seo?.metaTitle}
				description={page?.seo?.metaDescription}
				image={page?.seo?.socialImage}
			/>
			<PageHeader content={page?.header} page={page}/>
			<RenderModules modules={page?.modules} />
			<AddToCart>
				{/* <Heading>Price</Heading>
				<Price>${page?.store?.variants[0]?.store?.price}</Price> */}
				<Buttons>
					<AddButton 
						bgColor='dteal'
						icon='Rarr'
						href={page?.externalLink}
						// onClick={available ? () => add() : null} 
						css={css`
						pointer-events: ${available ? 'all' : 'none'};s
					`}>
						View course
					</AddButton>
					{/* <AddButton 
						bgColor='dteal'
						icon='Rarr'
						onClick={available ? () => add() : null} 
						css={css`
						pointer-events: ${available ? 'all' : 'none'};s
				`}>
						{available ? 'Add to Cart' : 'Sold Out'}
					</AddButton>
					<ViewCartDesk
						show={viewCart}
						outline
						icon='Cart'
						link={{
							linkType: 'internal', 
							document:{
								slug: {
									current: 'cart'
								}
							}
						}}>
						View cart
					</ViewCartDesk>
					<SlideDown>
						{viewCart && 
							<ViewCartMob
								show={viewCart}
								outline
								icon='Cart'
								link={{
									linkType: 'internal', 
									document:{
										slug: {
											current: 'cart'
										}
									}
								}}>
								View cart
							</ViewCartMob>
						}
					</SlideDown> */}
				</Buttons>
			</AddToCart>
			<DataViewer data={page} name="page"/>
		</>
	)
}

const AddToCart = styled(Section)`
	padding: 100px 0;
	${mobile}{
		padding: 64px 0;
	}
`
const Heading = styled.h4`
	grid-column: span 12;
	margin-bottom: var(--s);
`

const Price = styled.h5`
	display: flex;
	align-items: center;
	padding: 0 var(--xs);
	box-sizing: border-box;
	background-color: var(--beige60);
	grid-column: span 2;
	min-height: 60px;
	${mobile}{
		grid-column: span 12;
		margin-bottom: var(--l);
		max-width: 180px;
	}
`
const Buttons = styled.div`
	grid-column: span 10;
	${mobile}{
		grid-column: span 12;
	}
`
const AddButton = styled(Button)`
	margin-right: 30px;
	${mobile}{
		margin-bottom: var(--s);
	}
`
const ViewCartDesk = styled(Button)`
	opacity: ${props => props.show ? '1' : '0'};
	pointer-events: ${props => props.show ? 'all' : 'none'};
	transition: opacity 0.25s;
	${mobile}{
		display: none;
	}
`
const ViewCartMob = styled(ViewCartDesk)`
	display: none;
	${mobile}{
		display: flex;
	}
`

export const query = graphql`
	query ProductQuery($slug: String) {
		page: sanityProduct(store: {slug: {current: {eq: $slug}}}) {
			_type
			store {
				title
				slug {
					current
				}
				variants {
					store {
						id
						price
					}
				}
			}
			header {
				...pageHeader
			}
			externalLink
			modules{
				... on SanityArticleCollection {
					...articleCollection
				}
				... on SanityStoryCollection {
					...storyCollection
				}
				... on SanityProductCollection {
					...productCollection
				}
				... on SanitySignsSymptoms {
					...signsSymptoms
				}
				... on SanityHelpLine {
					_key
					_type
					include
				}
				... on SanityChatBotModule {
					_key
					_type
					include
				}
				... on SanityChecklist {
					_key
					_type
				}
				... on SanityQuickLinks {
					...quickLinks
				}
				... on SanitySmartSearch {
					...smartSearch
				}
				... on SanityFaqBlock {
					...faqBlock
				}
				... on SanityTextImage {
					...textImage
				}
				... on SanityFreeText {
					...freeText
				}
				... on SanityCenteredText {
					...centeredText
				}
				... on SanityFileCollection {
					...fileCollection
				}
				... on SanityTeam {
					...team
				}
				... on SanityLogoGrid {
					...logoGrid
				}
				... on SanityVideo {
					...video
				}
				... on SanityPodcast {
					...podcast
				}
				... on SanityContactForm {
					...contactForm
				}
				... on SanityFormAssemblyEmbed {
					...formAssemblyEmbed
				}
				... on SanityTextColumns {
					...textColumns
				}
				... on SanityQuote {
					...quote
				}
				... on SanityCampaign {
					...campaign
				}
				... on SanityEmbed {
					_key
					_type
					embedCode
				}
			}
			seo{
				...seo
			}
		}
	}
`

Product.propTypes = {
	data: PropTypes.object,
	daat: PropTypes.object, 
}

export default Product